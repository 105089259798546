.container {
  margin: 0;
  border: 0;
  background-color: #fff;
  /* display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap; */
}

.card {
  /* background-color: #edf733; */
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px;
  border-radius: 5px;
  flex-wrap: wrap-reverse;
  -webkit-box-shadow: 0px 3px 8px 1px rgba(0, 0, 0, 0.21);
  -moz-box-shadow: 0px 3px 8px 1px rgba(0, 0, 0, 0.21);
  box-shadow: 0px 3px 8px 1px rgba(0, 0, 0, 0.21);
}

.cardRight {
  flex: 5;
  display: flex;
  width: 400px;
  align-items: center;
  justify-content: space-around;
  color: #000000;
}
.cardRightImg {
  max-height: 50vh;
  /* width: 350px; */
  /* height: 32px; */
  border-radius: 5px;
  object-fit: cover;
  margin: 10px;
}

.cardLeft {
  flex: 3;
  display: flex;
  align-items: center;
  justify-content: space-around;
  color: #ec4545;
}

.cardLeftImg {
  /* width: 350px; */
  max-height: 70vh;
  border-radius: 5px;
  object-fit: cover;
  margin: 5px;
}

/* .topbarLeft {
  flex: 3;
}

.logo {
  font-size: 24px;
  margin-left: 20px;
  font-weight: bold;
  color: #fff;
  cursor: pointer;
}

.topbarCenter {
  flex: 5;
}

.searchbar {
  width: 100%;
  height: 30px;
  background-color: #fff;
  border-radius: 30px;
  display: flex;
  align-items: center;
}

.searchIcon {
  font-size: 20px !important;
  margin-left: 10px;
}

.searchInput {
  border: none;
  width: 70%;
}

.searchInput:focus {
  outline: none;
}




 */
