.feed {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 250px;
  background-color: #ffffff00;
}

.feedTop {
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: center;
  padding: 0px 30px 10px 0;
}

.feedTitle {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.feedTitleText {
  pointer-events: none;
  color: rgb(182, 182, 182);
  font-weight: 500;
}
.feedTitleLine {
  width: 30px;
  height: 5px;
  margin-top: 5px;
  background-color: red;
  border-radius: 5px;
}

.feedTitle:hover {
  .feedTitleLine {
    width: 150px;
    transition: 0.5s;
  }
}

.feedBottom {
  display: flex;
  flex-direction: row-reverse;
  align-items: start;
  justify-content: space-around;
  height: 100%;
  margin: 0 10px;
  flex-wrap: wrap;
}

.link {
  color: inherit;
  text-decoration: inherit;
}
.feedBottomItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 200px;
  height: 150px;
  background-color: #47f3fc73;
  border-left: 1px solid rgb(247, 247, 247);
  border-right: 1px solid rgb(247, 247, 247);
  border-radius: 10px;
  margin: 10px;
}
.feedBottomItemIcon {
  font-size: 40px;
  font-weight: bold;
  margin-bottom: 20px;
  color: rgb(127, 127, 127);
}
.feedBottomItemTitle {
  color: rgb(104, 104, 104);
  font-weight: 500;
  font-size: 15px;
}
.feedBottomItem:hover {
  .item {
    border-left: 1px solid rgb(222, 222, 222);
    border-right: 1px solid rgb(222, 222, 222);
    background-color: rgb(241, 241, 241);
  }
  .feedBottomItemIcon {
    color: red;
  }
  .feedBottomItemTitle {
    color: red;
  }
}

/* @include media(M) {
  .feed {
    margin: 0;
    padding: 20px;
    .title {
      font-size: 25px;
    }
    .desc {
      font-size: 12px;
    }
  }
} */
