.languageSelectorContainer {
  display: flex;
  position: absolute;
  display: flex;
  flex-direction: column;
  background-color: #0dc1ac;
  width: 170px;
  height: 200px;
  /* top: 70px; */
  top: 65px;
  left: 5px;
  border-radius: 10px;
}
.languageSelectorButton {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  border: none;
  margin: 5px;
  border-radius: 10px;
  background-color: #fff;
  font-size: 20px;
}

.languageSelectorButton:hover {
  background-color: #eeeeee;
}

@media screen and (max-width: 600px) {
  .languageSelectorContainer {
    width: 70%;
    height: 150px;
    top: 85px;
    left: 10px;
  }
}

/* CSS for screens with width greater than 400px */
@media screen and (min-width: 601px) {
}
