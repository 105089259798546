.footerContainer {
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.footerTop {
  display: flex;
  background-color: #00a693;
  justify-content: center;
}
.footer {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 80%;
  justify-content: center;
}
.footerRight {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;

  direction: rtl;
  color: #fff;

  margin: 40px 0px;
}
.footerCenter {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 40px 0px;
}

.footerCenterItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 10px 0;
}
.footerCenterItemTitle {
  margin: 5px;
  color: #352d17;
}
.footerCenterItemText {
  display: flex;
  align-items: start;
  justify-content: end;
  color: #fff;
  margin: 0;
}

.footerLeft {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 30px 0px;
}
.footerLeftIcon {
  /* font-size: 50px; */
  color: #fff;
  cursor: pointer;
}

.footerBottomLine {
  display: flex;
  height: 50px;
  background-color: #035f54;
  align-items: center;
  justify-content: center;
}

/* #1c5664 */
