.cards {
  display: flex;
  margin-top: 30px;
  margin: 0px;
  flex-wrap: wrap;
  flex-direction: row-reverse;
}

.cardsLeft {
  min-width: 220px;
}

.cardsFrame {
  overflow: hidden;
}

.cardsSlider {
  display: flex;
}
.cardsSliderImg {
  width: 20%;
  height: 500px;
  object-fit: cover;
}
.cardsSliderImg:hover {
  width: 40%;
  transition: 0.5s;
}

.cardsRight {
  min-width: 220px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: end;
  margin: 20px;
}

.cardsTop {
  width: 95%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
}
.cardsTopTitle {
  display: flex;
  justify-content: center;
  font-weight: 500;
  font-size: 25px;
  color: rgb(157, 157, 157);
}
.cardsTopLine {
  width: 30px;
  height: 5px;
  margin-top: 2px;
  background-color: red;
  border-radius: 5px;
}
.cardsTop:hover {
  .cardsTopLine {
    width: 150px;
    transition: 0.5s;
  }
}

p {
  text-align: end;
  font-size: 15px;
  font-weight: 200;
  color: rgb(84, 84, 84);
  margin: 20px;
}

/* @include media(M) {
  .cards {
     flex-direction: column-reverse;
  }
 } */
