.banner {
  display: flex;
  flex-direction: row-reverse;
  margin: 0;
  background: url(../../assets/splash/sp01.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
  min-height: 500px;
}

.bannerRight {
  display: flex;
  min-width: 300px;
  align-self: center;
  justify-content: center;
  margin: 10px;
}

.bannerRightContent {
  display: flex;
  overflow: hidden;
  z-index: 0;
}

.bannerRightTitle {
  color: rgb(22, 146, 134);
  font-size: 2.5rem;
  font-weight: 600;
}

.bannerLeft {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px;
}

.bannerLeftSlider {
  display: flex;
  overflow: hidden;
  z-index: 0;
  align-items: center;
  justify-content: center;
}

.bannerLeftSlides {
  display: flex;
}

.bannerLeftSlide {
  display: flex;
  width: 300px;
}
.bannerLeftSlideImg {
  display: none;
}
.bannerLeftSlideImgActive {
  max-width: 100%;
  height: 200px;
}
