.navbarContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding: 10px 20px; */
  color: #fff;
  background-color: #00a693;
  position: sticky;
  direction: rtl;
  top: 0;
  height: 60px;
  z-index: 1000;
}
.navbarRight {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}

.hamburgerMenuButton {
  background: none;
  border: none;
  cursor: pointer;
  z-index: 1000;
}

.bar {
  width: 25px;
  height: 3px;
  background-color: white;
  margin: 5px 0;
  transition: 0.4s;
  border-radius: 2px;
}

.bar.open:nth-child(1) {
  transform: rotate(-45deg) translate(-5px, 6px);
}

.bar.open:nth-child(2) {
  opacity: 0;
}

.bar.open:nth-child(3) {
  transform: rotate(45deg) translate(-5px, -6px);
}

.navbarRightLogo {
  cursor: pointer;
}
.navbarRightLogo.open {
  animation-name: logoMotion;
  animation-duration: 2s;
  cursor: pointer;
}
.link {
  color: inherit;
  text-decoration: inherit;
}
@keyframes logoMotion {
  0% {
    transform: translateX(20);
  }
  25% {
    transform: translateX(-20px);
  }
  50% {
    transform: translateX(500px);
  }
  75% {
    transform: translateX(-20px);
  }
  100% {
    transform: translateX(0px);
  }
}

.navbarCenter {
  display: flex;
  align-items: center;
  justify-content: start;
  flex-direction: row;
  flex: 3;
  transition: max-height 0.3s ease-in-out;
  max-height: 0;
}

.navbarCenterUL {
  list-style: none;
  padding: 0;
}
.navbarCenterLi {
  font-size: 18px;
  font-weight: 500;
  display: flex;
  margin-left: 20px;
  cursor: pointer;
}
.navbarLeft {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.navbarIcons {
  display: flex;
  flex-direction: row-reverse;
}
.navbarIcon {
  margin-left: 20px;
  cursor: pointer;
}

.sidebar {
  position: fixed;
  top: 60px;
  right: -250px; /* مخفی شده از سمت راست */
  width: 250px;
  height: 100%;
  padding-top: 10px;
  background-color: #00a693;
  transition: right 0.3s ease; /* انیمیشن حرکت سایدبار */
}

.sidebar.open {
  right: 0; /* نمایش سایدبار از سمت راست */
}

/* آیتم‌های سایدبار */
.sidebar ul {
  list-style: none;
  padding: 0;
  margin-right: 10px;
}

.sidebar li {
  padding: 10px;
  color: white;
  cursor: pointer;
}

@media screen and (max-width: 600px) {
  .navbarCenter {
    display: none;
  }

  .navbarCenterUL.open {
    display: block;
  }

  .navbarContainer {
    padding: 10px 0;
  }

  .navbarRight {
    flex: 5;
    justify-content: space-between;
  }
  .navbarLeft {
    flex: 4;
    justify-content: end;
  }
  .navbarIcon {
    margin-left: 7px;
    margin-right: 13px;
  }
}

/* CSS for screens with width greater than 400px */
@media screen and (min-width: 601px) {
  .hamburgerMenuButton {
    display: none;
  }

  .navbarCenterUL {
    display: flex !important;
  }

  .navbarRightLogo {
    transform: rotateY(180deg);
  }

  .sidebar {
    display: none;
  }
}
