.about {
  display: flex;
  flex-direction: column;
  min-height: 500px;
  background-color: rgb(60, 60, 60);
  align-items: center;
  /* justify-content: center; */
}

.aboutTop {
  width: 95%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  margin: 20px;
}

.aboutTopTitle {
  display: flex;
  justify-content: center;
  font-weight: 500;
  font-size: 25px;
  color: rgb(157, 157, 157);
}
.aboutTopLine {
  width: 30px;
  height: 5px;
  margin-top: 5px;
  background-color: red;
  border-radius: 5px;
}
.aboutTop:hover {
  .aboutTopLine {
    width: 100px;
    transition: 0.5s;
  }
}
.aboutBottom {
  width: 60%;

  display: flex;
  /* align-items: center; */
  justify-content: center;
  margin: 10px;
}

.aboutBottomText {
  text-align: justify;
  direction: rtl;
  font-size: 20px;
  color: rgb(143, 143, 143);
}
