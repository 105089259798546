body {
  /* font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif, ma; */
  font-family: "main";
  margin: 0;
  padding: 0;
  background-color: #f4f4f4;
}

@font-face {
  font-family: "main";
  src: url("../public/fonts/iransansxv.woff2") format("woff2-variations");
  font-weight: 100 1000;
  font-display: fallback;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
